import { ATTRIBUTE_PRODUCT_IMAGE_SLIDER } from './product-image-slider.ts';

const $slider = document.querySelector<HTMLDivElement>(`[${ATTRIBUTE_PRODUCT_IMAGE_SLIDER}]`);

if ($slider) {
	import('./product-image-slider.ts').then(({ default: loadProductImageSlider }) => {
		loadProductImageSlider($slider);
	});
}

export {};
