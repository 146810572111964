import { ATTRIBUTE_PRODUCT_FILTER } from './product-filter.ts';

const $filter = document.querySelector<HTMLFormElement>(`[${ATTRIBUTE_PRODUCT_FILTER}]`);

if ($filter) {
	import('./product-filter.ts').then(({ default: loadProductFilter }) => {
		loadProductFilter($filter);
	});
}

export {};
