import { ATTRIBUTE_WEB_SHARE } from './web-share.ts';

const $buttons = document.querySelectorAll<HTMLButtonElement>(`[${ATTRIBUTE_WEB_SHARE}]`);

if ($buttons.length) {
	import('./web-share.ts').then(({ default: loadWebShare }) => {
		loadWebShare($buttons);
	});
}

export {};
