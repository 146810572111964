const SELECTOR_FORM_SEARCH = '[data-cs-header-search]';

const $forms = document.querySelectorAll<HTMLFormElement>(SELECTOR_FORM_SEARCH);

if ($forms.length) {
	import('./header-search.ts').then(({ default: loadHeaderSearch }) => {
		loadHeaderSearch($forms);
	});
}

export {};
