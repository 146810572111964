import Splide from '@splidejs/splide';

export const ATTRIBUTE_SLIDER = 'data-cs-slider';

export default function loadSlider($sliders: NodeListOf<HTMLDivElement>) {
	$sliders.forEach(($slider) => {
		new Splide($slider, {
			type: $slider.getAttribute(`${ATTRIBUTE_SLIDER}-type`) ?? 'loop',
		}).mount();
	});
}
