import { postEventProductAddToWishlist, postEventProductView } from '../lib/api';

async function eventProductView(product: unknown, viewName: string, widget: string, cart: unknown) {
	await postEventProductView(product, viewName, widget, cart);
}

async function eventProductAddToWishlist(product: unknown, viewName: string, widget: string) {
	await postEventProductAddToWishlist(product, viewName, widget);
}

window.eventProductView = eventProductView;
window.eventProductAddToWishlist = eventProductAddToWishlist;
