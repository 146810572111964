// Alert

const $alerts = document.querySelectorAll<HTMLElement>(`.alert`);

if ($alerts.length) {
	import('bootstrap/js/dist/alert.js');
}

// Collapse

const $collapsibles = document.querySelectorAll<HTMLElement>(`.collapse`);

if ($collapsibles.length) {
	import('bootstrap/js/dist/collapse.js');
}

// Dropdown

const $dropdowns = document.querySelectorAll<HTMLElement>(`.dropdown-toggle`);

if ($dropdowns.length) {
	import('bootstrap/js/dist/dropdown.js');
}

// Modal

const $modals = document.querySelectorAll<HTMLElement>(`.modal`);

if ($modals.length) {
	import('bootstrap/js/dist/modal.js');
}

// Offcanvas

const $offcanvases = document.querySelectorAll<HTMLElement>(`.offcanvas,.offcanvas-md,.offcanvas-lg`);

if ($offcanvases.length) {
	import('bootstrap/js/dist/offcanvas.js');
}

// Tab

const $tabContents = document.querySelectorAll<HTMLElement>(`.tab-content`);

if ($tabContents.length) {
	import('bootstrap/js/dist/tab.js');
}

// Toast

const $toasts = document.querySelectorAll<HTMLElement>(`.toast`);

if ($toasts.length) {
	import('bootstrap/js/dist/toast.js').then(({ default: Toast }) => {
		$toasts.forEach(($toast) => Toast.getOrCreateInstance($toast).show());
	});
}
