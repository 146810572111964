export const SELECTOR_SHOW_SUB_MENU = '[data-cs-nav-show-sub-menu]';

export default function loadNavShowSubMenu($submenus: NodeListOf<HTMLDivElement>) {
	$submenus.forEach(($submenu) => {
		const $activeLinks = $submenu.querySelectorAll<HTMLLinkElement>(`${SELECTOR_SHOW_SUB_MENU} a.active`);
		if (!$activeLinks) return;

		$activeLinks.forEach(($activeLink) => {
			let $element: HTMLElement | null = $activeLink;

			do {
				if ($element.nextElementSibling?.classList.contains('collapse')) {
					$element.nextElementSibling.classList.add('show');
					const $triggerElement = $element.nextElementSibling.parentElement?.querySelector<
						HTMLButtonElement | HTMLLinkElement
					>('[aria-controls]');
					if ($triggerElement) {
						$triggerElement.setAttribute('aria-expanded', 'true');
						$triggerElement.classList.remove('collapsed');
					}
				}

				$element = $element.parentElement;

				if ($element?.previousElementSibling?.hasAttribute('aria-expanded')) {
					$element.previousElementSibling.classList.add('active');
					const $triggerElement = $element.previousElementSibling.parentElement?.querySelector<
						HTMLButtonElement | HTMLLinkElement
					>('[aria-controls]');
					if ($triggerElement) {
						$triggerElement.setAttribute('aria-expanded', 'true');
						$triggerElement.classList.remove('collapsed');
					}
				}

				if ($element?.classList.contains('collapse')) {
					$element.classList.add('show');
					const $triggerElement = $element.parentElement?.querySelector<HTMLButtonElement | HTMLLinkElement>(
						'[aria-controls]',
					);
					if ($triggerElement) {
						$triggerElement.setAttribute('aria-expanded', 'true');
						$triggerElement.classList.remove('collapsed');
					}
				}
			} while ($element && $element !== $submenu);
		});
	});
}
