import PhotoSwipeLightbox from 'photoswipe/lightbox';

export const ATTRIBUTE_PHOTOSWIPE = 'data-cs-photoswipe';

export default function loadPhotoswipe($photoswipe: HTMLDivElement) {
	let animation = $photoswipe.getAttribute(`${ATTRIBUTE_PHOTOSWIPE}-animation`);
	if (animation !== 'fade' && animation !== 'zoom' && animation !== 'none') {
		animation = null;
	}

	const lightbox = new PhotoSwipeLightbox({
		gallery: `[${ATTRIBUTE_PHOTOSWIPE}]`,
		children: 'a',
		showHideAnimationType: animation ?? 'fade',
		zoomAnimationDuration: false,
		pswpModule: () => import('photoswipe'),
	});

	lightbox.init();
}
