import Splide from '@splidejs/splide';

import { GRID_BREAKPOINT_MD } from '../../shared/lib/bootstrap.ts';

export const ATTRIBUTE_PRODUCT_IMAGE_SLIDER = 'data-cs-product-image-slider';

/**
 * @see https://splidejs.com/tutorials/thumbnail-carousel/
 */
export default function loadProductSlider($slider: HTMLDivElement) {
	const $images = $slider.querySelector<HTMLDivElement>(`[${ATTRIBUTE_PRODUCT_IMAGE_SLIDER}-images]`);
	if (!$images) return;

	const $thumbnails = $slider.querySelector<HTMLDivElement>(`[${ATTRIBUTE_PRODUCT_IMAGE_SLIDER}-thumbnails]`);
	if (!$thumbnails) return;

	const imagesSlider = new Splide($images, {
		arrows: false,
		pagination: false,
		rewind: true,
		type: 'fade',
	});

	const thumbnailsSlider = new Splide($thumbnails, {
		arrows: false,
		fixedHeight: 100, // px
		fixedWidth: 100, // px
		focus: 'center',
		gap: 10, // px
		isNavigation: true,
		pagination: false,
		rewind: true,
		breakpoints: {
			[GRID_BREAKPOINT_MD]: {
				fixedWidth: 60, // px
				fixedHeight: 60, // px
			},
		},
	});

	imagesSlider.sync(thumbnailsSlider);
	imagesSlider.mount();
	thumbnailsSlider.mount();
}
