import { SELECTOR_SHOW_SUB_MENU } from './nav-show-sub-menu.ts';

const $submenus = document.querySelectorAll<HTMLDivElement>(SELECTOR_SHOW_SUB_MENU);

if ($submenus.length) {
	import('./nav-show-sub-menu.ts').then(({ default: loadNavShowSubMenu }) => {
		loadNavShowSubMenu($submenus);
	});
}

export {};
