import 'photoswipe/dist/photoswipe.css';
import { ATTRIBUTE_PHOTOSWIPE } from './photoswipe.ts';

const $photoswipe = document.querySelector<HTMLDivElement>(`[${ATTRIBUTE_PHOTOSWIPE}]`);

if ($photoswipe) {
	import('./photoswipe.ts').then(({ default: loadPhotoswipe }) => {
		loadPhotoswipe($photoswipe);
	});
}

export {};
