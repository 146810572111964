import { importAxios } from '../../shared/lib/axios';

export interface SearchResult {
	get_absolute_url: string;
	id: number;
	name: string;
	search_image_preview_url: string | null;
}

export async function getSearchbox(params: { query: string }) {
	const axios = await importAxios();
	return await axios.get<{
		highest_count: number;
		next: string | null;
		overall_total: number;
		previous: string | null;
		results: {
			brand: Array<SearchResult>;
			category: Array<SearchResult>;
			product: Array<SearchResult>;
		};
	}>('/searchbox/', {
		params: {
			search: params.query,
		},
	});
}

export async function getCart() {
	const axios = await importAxios();
	return await axios.get<{
		cart_amount_up_to_free_shipping: string;
		cart_amount_up_to_free_shipping_print: string;
		cart_final: string;
		cart_final_print: string;
		cart_savings: string;
		cart_savings_print: string;
		cart_shipping_expenses: string;
		cart_shipping_expenses_print: string;
		cart_taxes: string;
		cart_taxes_print: string;
		cart_total: string;
		cart_total_print: string;
	}>('/cart/');
}

export async function putCartNote(data: { note: string }) {
	const axios = await importAxios();
	return await axios.put<{ note: string }>('/cart/note/', {
		note: data.note,
	});
}

export async function putCartShipping(data: { shipping: number }) {
	const axios = await importAxios();
	return await axios.put<{ shipping: number }>('/cart/shipping/', {
		shipping: data.shipping,
	});
}

export async function putCartPaymentMethod(data: { paymentMethod: number }) {
	const axios = await importAxios();
	return await axios.put<{ paymentmethod: number }>('/cart/payment-method/', {
		paymentmethod: data.paymentMethod,
	});
}

export async function getUserSearchHistory() {
	const axios = await importAxios();
	return await axios.get<{
		results: { id: string; search_term: string }[];
	}>('/searchbox-user-search-history/');
}

export async function clearUserSearchHistory() {
	const axios = await importAxios();
	return await axios.get<void>('/searchbox-clear-user-search-history/');
}

export async function getUserContentHistory() {
	const axios = await importAxios();
	return await axios.get<{
		results: {
			get_absolute_url: string;
			id: number;
			name: string;
			search_image_preview_url: string | null;
		}[];
	}>('/searchbox-user-content-history/');
}

export async function clearUserContentHistory() {
	const axios = await importAxios();
	return await axios.get<void>('/searchbox-clear-user-content-history/');
}

export async function postEventProductView(product_id: unknown, viewName: string, widget: string, cart_db_id: unknown) {
	const axios = await importAxios();
	return await axios.post<void>(
		'/event/product/view',
		{
			action_object: product_id,
			page: viewName,
			widget: widget,
			target: cart_db_id,
		},
		{ baseURL: '' },
	);
}

export async function postEventProductAddToWishlist(product_id: unknown, viewName: string, widget: string) {
	const axios = await importAxios();
	return await axios.post<void>(
		'/event/product/add-to-wishlist',
		{
			action_object: product_id,
			page: viewName,
			widget: widget,
		},
		{ baseURL: '' },
	);
}
