import { ATTRIBUTE_SLIDER } from './slider.ts';

const $sliders = document.querySelectorAll<HTMLDivElement>(`[${ATTRIBUTE_SLIDER}]`);

if ($sliders.length) {
	import('./slider.ts').then(({ default: loadSlider }) => {
		loadSlider($sliders);
	});
}

export {};
