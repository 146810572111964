// Events

export const EVENT_ALERT_CLOSED = 'closed.bs.alert';
export const EVENT_DROPDOWN_SHOW = 'show.bs.dropdown';
export const EVENT_MODAL_HIDDEN = 'hidden.bs.modal';
export const EVENT_MODAL_SHOW = 'show.bs.modal';
export const EVENT_OFFCANVAS_SHOW = 'show.bs.offcanvas';
export const EVENT_TAB_SHOWN = 'shown.bs.tab';

// Attributes

export const ATTRIBUTE_POPPER = 'data-bs-popper';
export const ATTRIBUTE_VALUE_POPPER_STATIC = 'static';

// Grid breakpoints

// export const GRID_BREAKPOINT_XS = 0;
// export const GRID_BREAKPOINT_SM = 576;
export const GRID_BREAKPOINT_MD = 768;
export const GRID_BREAKPOINT_LG = 992;
// export const GRID_BREAKPOINT_XL = 1200;
// export const GRID_BREAKPOINT_XXL = 1400;

export const GRID_BREAKPOINTS_STEP = 0.02;
