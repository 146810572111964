import { GRID_BREAKPOINT_MD, GRID_BREAKPOINTS_STEP } from '../../shared/lib/bootstrap.ts';

export const ATTRIBUTE_PRODUCT_FILTER = 'data-cs-product-filter';

export default function loadProductFilter($filter: HTMLFormElement) {
	const $collapsibles = $filter.querySelectorAll<HTMLDivElement>(`[${ATTRIBUTE_PRODUCT_FILTER}-collapse]`);
	if (!$collapsibles.length) return;

	const mediaQueryList = matchMedia(`(max-width: ${GRID_BREAKPOINT_MD - GRID_BREAKPOINTS_STEP}px)`);
	updateCollapsibleStates($collapsibles, mediaQueryList.matches);
	mediaQueryList.addEventListener('change', (mediaQueryList) => {
		updateCollapsibleStates($collapsibles, mediaQueryList.matches);
	});
}

function updateCollapsibleStates($elements: NodeListOf<HTMLDivElement>, shouldHide: boolean) {
	$elements.forEach(($element) => {
		const $trigger = $element.parentElement?.querySelector<HTMLButtonElement | HTMLLinkElement>('[aria-controls]');

		if (shouldHide) {
			$element.classList.remove('show');

			if ($trigger) {
				$trigger.setAttribute('aria-expanded', 'false');
				$trigger.classList.add('collapsed');
			}
		} else {
			$element.classList.add('show');

			if ($trigger) {
				$trigger.setAttribute('aria-expanded', 'true');
				$trigger.classList.remove('collapsed');
			}
		}
	});
}
